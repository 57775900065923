import(/* webpackMode: "eager", webpackExports: ["ReactQueryDevtools"] */ "/app/.pnpm/@tanstack+react-query-devtools@4.36.1_@tanstack+react-query@4.36.1_react-dom@18.3.1_react@18._7so3kfcjh5wrfohcrcdehpw344/node_modules/@tanstack/react-query-devtools/build/lib/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/.pnpm/next-intl@3.19.4_next@14.2.7_patch_hash=fgzp43fp2nge77xfax2kadchme_@babel+core@7.25.2_@opente_27csy4seref55zcruk63itlvca/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/.pnpm/next@14.2.7_patch_hash=fgzp43fp2nge77xfax2kadchme_@babel+core@7.25.2_@opentelemetry+api@1.9.0_kc3mbx5rlm3t7mkfz7ycpcxtj4/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/app/[lang]/components/Cookie/CookieBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/app/[lang]/components/Modal/GlobalConfirmModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/app/[lang]/JotaiProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/app/[lang]/ReactQueryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/shared/lib/rehydrate/rehydrate.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/oneplatform/src/shared/ui/modal/global-modal-v2.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/oneplatform/src/styles/globals.css");
